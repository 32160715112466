<template>
  <div class="add_editor">
    <div class="min_details">
      <div class="title">内容详情</div>
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <el-form-item label="线索状态" prop="">
          <el-radio-group  class="readonly-radio-group" v-model="form.type">
            <el-radio disabled label="业务内容"></el-radio>
            <el-radio disabled label="宣传物料"></el-radio>
            <!-- <el-radio disabled label="节日祝福"></el-radio> -->
            <el-radio disabled label="其他"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="消息内容" prop="">
          <div class="context_flex" v-html="form.context"></div>
        </el-form-item>
      </el-form>
    </div>

    <div class="footer">
      <el-button class="back_color" type="primary" plain @click="routerGo">返回</el-button>
    </div>
  </div>
</template>
  
  <script>
import { getMessageInfoDetail } from '@/api/operatorCenter'

export default {
  name: 'details',
  computed: {
    archiveId() {
      //存在是编辑 不存在为新增
      return this.$route.query.id
    }
  },
  data() {
    return {
      form: {}
    }
  },
  created() {
    this.getMessageInfoDetail()
  },
  methods: {
    routerGo() {
      this.$router.go(-1)
    },
    async getMessageInfoDetail() {
      const {data:res} = await getMessageInfoDetail({ id: this.archiveId })
     
      if (res.resultCode == 200) {
        
        this.form = res.data
        console.log(this.form);
        
      }
    }
  }
}
</script>
  
  <style lang="less" scoped>

::v-deep .el-radio__input.is-disabled+span.el-radio__label {
    color: #333333;
    cursor: auto;
}
::v-deep .context_flex  {
  margin-top: 15px;
  width: 800px;
  min-height: 35px;
  background: #fafafa;
  padding:5px 15px;
  video{
    width: 400px;
    height: 200px;
  }
  p{
    line-height: 10px;
  }
}
.add_editor {
  overflow: hidden;
  background: #fff;
  padding: 15px;
  .min_details {
    height: calc(100vh - 280px);
  }
  .footer {
    border-top: 1px solid #ececec;
    padding-top: 20px;
    width: 100%;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .back_color {
      background: #fff;
      color: #448aff;
    }
  }

  .title {
    width: 100%;
    overflow: hidden;
    letter-spacing: 2px;
    color: #4e93fb;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}
</style>
  